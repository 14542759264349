import React, { useState, useEffect } from 'react';
import socket from "../../services/socket";
// import Leaderboard from './Leaderboard';
import './stylesheets/Competition.scss';

function Competition(props) {
  const [lastSubmittedQuestionIndex, setLastSubmittedQuestionIndex] = useState(null);
  // const [correctAnswer, setCorrectAnswer] = useState(null);
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null);
  const [lastSelectedAnswerIndex, setLastSelectedAnswerIndex] = useState(null);
  // const [viewLeaderboard, setViewLeaderboard] = useState(false);
  const [questionTimerPercentage, setQuestionTimerPercentage] = useState(100);

  const selectAnswer = (answerIndex) => {
    setSelectedAnswerIndex(answerIndex)
  }

  // submit answer
  useEffect(() => {
    if (
      props.config.gameData.currentQuestionIndex !== lastSubmittedQuestionIndex
      && props.config.gameData.questionTimer === 0
    ) {
      socket.emit('message', JSON.stringify({
        type: 'submitTriviaAnswer',
        answerIndex: selectedAnswerIndex,
        questionIndex: props.config.gameData.currentQuestionIndex
      }));

      setLastSubmittedQuestionIndex(props.config.gameData.currentQuestionIndex)
      setLastSelectedAnswerIndex(selectedAnswerIndex)
      setSelectedAnswerIndex(null)
    }
  }, [props.config.gameData.currentQuestionIndex, props.config.gameData.questionTimer, lastSubmittedQuestionIndex, selectedAnswerIndex]);

  // evaluate answer
  useEffect(() => {
    if (!props.config.gameData.currentQuestion) return
    // setCorrectAnswer(
    //   props.config.gameData.currentQuestion.correctAnswerIndex === selectedAnswerIndex ||
    //   props.config.gameData.currentQuestion.correctAnswerIndex === lastSelectedAnswerIndex
    // )
  }, [props.config.gameData.currentQuestion, selectedAnswerIndex, lastSelectedAnswerIndex])

  // show user leaderboard if they have missed any questions
  // useEffect(() => {
  //   if (
  //     props.config.gameData.currentQuestionIndex
  //     && (
  //       !props.config.gameData.triviaRecord
  //       || (props.config.gameData.triviaRecord && props.config.gameData.triviaRecord.correctAnswers < props.config.gameData.currentQuestionIndex)
  //     )
  //   ) {
  //     setViewLeaderboard(true)
  //   }
  // }, [props.config.gameData.currentQuestionIndex, props.config.gameData.triviaRecord])

  // reset selected answer
  useEffect(() => {
    setSelectedAnswerIndex(null)
  }, [props.config.gameData.currentQuestion])

  useEffect(() => {
    if (props.config.gameData.questionTimer === props.config.gameData.questionTimeLimitInSeconds) {
      setQuestionTimerPercentage(100)
      process.nextTick(() => {
        setQuestionTimerPercentage((props.config.gameData.questionTimer - 1) / props.config.gameData.questionTimeLimitInSeconds * 100)
      })
    } else {
      setQuestionTimerPercentage((props.config.gameData.questionTimer - 1) / props.config.gameData.questionTimeLimitInSeconds * 100)
    }
  }, [props.config.gameData.questionTimer, props.config.gameData.questionTimeLimitInSeconds])

  const secondsToMMSS = (n) => {
      n = parseInt(n, 10);
      var minutes = Math.floor((n / 60));
      var seconds = n - (minutes * 60);

      if (minutes < 10) {minutes = "0"+minutes;}
      if (seconds < 10) {seconds = "0"+seconds;}
      return minutes+':'+seconds;
  }

  // if (viewLeaderboard) {
  //   return <Leaderboard {...props}/>
  // }

  return(
    <div className="competition main-content">
      <div className="labels">
        <h5>Question {Number.isInteger(props.config.gameData.currentQuestionIndex) ? props.config.gameData.currentQuestionIndex + 1 : ''}</h5>
        <h5 className="time">{secondsToMMSS(props.config.gameData.questionTimer)}</h5>
      </div>
      <div className="progress">
        {props.config.gameData.answerTimer !== 1 &&
          <div className={`bar ${questionTimerPercentage === 100 ? 'reset': ''}`} style={{width: `${questionTimerPercentage}%`}}></div>
        }
      </div>
      <div className="progress-bar"></div>
      { props.config.gameData.currentQuestion &&
        <div className={!props.config.gameData.questionTimer ? 'answer-phase' : ''}>
          <p className="question">{ props.config.gameData.currentQuestion.question }</p>
          <div className="answers">
            { props.config.gameData.currentQuestion.answers.map((answer, index) => (
              <div
                className={`answer-wrapper ${props.config.gameData.questionTimer ? '' : index === props.config.gameData.currentQuestion.correctAnswerIndex ? 'correct' : 'incorrect'}`}
                key={index}
              >
                <button
                  onClick={() => selectAnswer(index)}
                  disabled={!props.config.gameData.questionTimer}
                  className={`
                    ${index === lastSelectedAnswerIndex ? 'last-selected' : ''}
                    ${index === selectedAnswerIndex ? 'selected' : ''}
                  `}
                >{ answer }</button>
                <p className="explanation">
                  { props.config.gameData.questionTimer ? '' : index === props.config.gameData.currentQuestion.correctAnswerIndex &&
                    <span className='txt--success'>Nailed it! </span>
                  }
                  { props.config.gameData.questionTimer ? '' : index !== props.config.gameData.currentQuestion.correctAnswerIndex &&
                    <span className='txt--error'>Sorry! </span>
                  }
                  { props.config.gameData.currentQuestion.explanation }
                </p>
              </div>
            ))}
            <div
              className={`answer-wrapper time-out incorrect`}
              style={{ opacity: !props.config.gameData.questionTimer && !Number.isInteger(lastSelectedAnswerIndex) ?  '1' : '0' }}
            >
              <button className="selected last-selected">You Timed-Out!</button>
              <p className="explanation">
                <span className='txt--error'>Sorry! </span>
                { props.config.gameData.currentQuestion.explanation }
              </p>
            </div>
          </div>
          { !props.config.gameData.questionTimer &&
            <div className="answer-timer">
              <h3>
                { (props.config.gameData.currentQuestionIndex + 1) !== props.config.gameData.totalQuestions &&
                  <span className="txt--gold-rich">Next question</span>
                }
                { (props.config.gameData.currentQuestionIndex + 1) === props.config.gameData.totalQuestions &&
                  <span className="txt--gold-rich">See How You Stacked Up</span>
                }
                <span className="time">{Number.isInteger(props.config.gameData.answerTimer) ? secondsToMMSS(props.config.gameData.answerTimer) : '00:00'}</span>
              </h3>
            </div>
          }
          {/* { !props.config.gameData.questionTimer && !correctAnswer &&
            <div>
              <h5 className="cta" onClick={() => setViewLeaderboard(true)}>View Leaderboard</h5>
            </div>
          } */}
        </div>
      }
    </div>
  )
}

export default Competition;
