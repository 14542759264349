import Leaderboard from './Leaderboard';

function PostGame(props) {
  if (props.config.gameData && props.config.gameData.triviaRecords) {
    return <Leaderboard {...props}/>
  } else {
    return <div className="post-game main-content">
      <h5>Tabulating Results</h5>
    </div>
  }
}

export default PostGame;
