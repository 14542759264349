import './stylesheets/styleguide.scss'

function Footer(props) {

  return(
    <footer>
        <div className='footer-content'>
            <hr></hr>
        </div>
    </footer>
  )
}

export default Footer;
