import imgs from '../../helpers/imgs'
import socket from "../../services/socket"
import './stylesheets/styleguide.scss'
import './stylesheets/Countdown.scss'
import {useEffect } from 'react'
import { getZamboniImageFromTeamIndex, getTeamNameFromIndex } from "../../helpers/zamboni";

function CountdownToStart(props) {

  useEffect(()=> {
    if (!props.config.gameData.assignedTeamIndex) {
      socket.emit('message', JSON.stringify({
        type: 'assign'
      }))
    }
  }, [props])

  return(
    <div className="countdown-to-start">
      <div className='countdown-content'>
        <div className="top-content">
          <hr className='hr-congrats'></hr>
          <h2>Congrats, you're on...</h2>
          <hr className='hr-congrats'></hr>
        </div>

        <div className='center-content'>
          <img className='zam' src={getZamboniImageFromTeamIndex(props.config.gameData.assignedTeamIndex || 0)} alt="red zamboni pixel art" />
          <h1> {getTeamNameFromIndex(props.config.gameData.assignedTeamIndex)} </h1>
          <hr></hr>
          <img className='swipe' src={imgs[1]} alt="finger swipe lefy and right icon" />
          <p>When the race begins, tap as fast as you can to help move your teams zamboni.</p>
        </div>

        <div className='bottom-content'>
        <div className='left LR'>
          <hr></hr>
          <p> Races begin </p>
          <hr></hr>
        </div>

        <div className='center'>
          <h3>{props.config.gameData.countdownTimer}</h3>
          <h4>second{ props.config.gameData.countdownTimer === 1 ? '': 's'}</h4>
        </div>

        <div className='right LR'>
          <hr></hr>
          <p>Good luck!</p>
          <hr></hr>
        </div>
      </div>

      </div>

    </div>
  )
}

export default CountdownToStart;
