import axios from 'axios'
import to from 'await-to-js'
import React, { useEffect, useState } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import LinearProgress from '@material-ui/core/LinearProgress'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import SaveIcon from '@material-ui/icons/Save'
import DeleteIcon from '@material-ui/icons/Delete'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
// import Switch from '@material-ui/core/Switch';
// import FormControl from '@material-ui/core/FormControl'
// import RadioGroup from '@material-ui/core/RadioGroup'
// import Radio from '@material-ui/core/Radio'
// import FormLabel from '@material-ui/core/FormLabel'
// import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import { ServerURL } from '../data/ServerURL'

function SettingsDialog(props) {
  const {
    open,
    onClose,
    getActions,
    setActions,
    actions,
    models,
    videos,
    images,
    animations,
    setSettingsDialogOpen,
  } = props

  const [changed, setChanged] = useState(false)
  const [changeSet, setChangeSet] = useState([])

  const setChange = change => {
    const _changeSet = [...changeSet]
    const _changeSetIndex = _changeSet.findIndex(({ id, type }) => id === change.id && type === change.type)

    if (_changeSetIndex !== -1) {
      _changeSet[_changeSetIndex] = change
    } else {
      _changeSet.push(change)
    }

    setChangeSet(_changeSet)
    if (_changeSet.length) setChanged(true)
    else setChanged(false)
  }

  const [metas, setMetas] = useState([])
  const [activeMeta, setActiveMeta] = useState(null)

  const setMeta = meta => {
    const _metas = [...metas]

    const _metaIndex = _metas.findIndex(({ id }) => id === meta.id)

    if (_metaIndex !== -1) {
      _metas[_metaIndex] = meta
    } else {
      _metas.push(meta)
    }

    setMetas(_metas)

    setChange({
      type: 'meta',
      id: meta.id,
      value: meta,
    })
  }

  const getMetas = async () => {
    const [err, res] = await to(axios.get(`${ServerURL}/api/meta`))

    if (err) {
      console.warn(err)
      return
    }

    const _metas = res?.data || []

    setMetas(_metas)

    if (_metas.length) {
      const _activeMeta = _metas.find(({ active }) => active)

      if (_activeMeta) {
        setActiveMeta(_activeMeta)
      }
    }
  }

  const metaById = _id => {
    return metas.find(({ id }) => id === _id)
  }

  const removeAction = action => {
    const _actions = [...actions]
    const _actionIndex = _actions.findIndex(({ id }) => id === action.id)
    const _videoIndex = videos.findIndex(({ id }) => id === action.id)
    const _modelIndex = models.findIndex(({ id }) => id === action.id)
    const _imageIndex = images.findIndex(({ id }) => id === action.id)

    if (_actionIndex !== -1) {
      _actions.splice(_actionIndex, 1)
    }
    if (_videoIndex !== -1) {
      videos.splice(_videoIndex, 1)
    }
    if (_modelIndex !== -1) {
      models.splice(_modelIndex, 1)
    }
    if (_imageIndex !== -1) {
      images.splice(_imageIndex, 1)
    }

    setActions(_actions)

    if(action.config) {
      action.config.delete = true;
    } else {
      action.config = {
        delete: true,
      }
    }

    setChange({
      type: 'action',
      id: action.id,
      value: action,
    })
  }

  const setAction = action => {
    const _actions = [...actions]
    const _actionIndex = _actions.findIndex(({ id }) => id === action.id)

    if (_actionIndex !== -1) {
      _actions[_actionIndex] = action
    } else {
      _actions.push(action)
    }

    setActions(_actions)

    setChange({
      type: 'action',
      id: action.id,
      value: action,
    })
  }

  const actionByIdAndType = ({ id, type }) => {
    return actions.find((action) => action.id === id && action.type === type)
  }

  const [loading, setLoading] = useState(false)

  const getSettings = async () => {
    if (loading) return

    setLoading(true)

    // TODO
    // await getActions()
    await getMetas()

    setLoading(false)
  }

  const [saving, setSaving] = useState(false)

  const handleRemoveClick = async (action) => {
    setSaving(true)
    removeAction(action);

    const [err] = await to(Promise.all(
      changeSet.map(async change => {
        await axios.post(`/api/${change.type}`, change.value)
      })
    ))

    if (err) console.warn(err)

    setSaving(false)
  }

  const handleSaveClick = async () => {
    setSaving(true)
    const [err] = await to(Promise.all(
      changeSet.map(async change => {
        await axios.post(`/api/${change.type}`, change.value)
      })
    ))

    if (err) console.warn(err)

    // reset changeset
    setChangeSet([])
    setChanged(false)
    setSaving(false)
    getActions()
    setSettingsDialogOpen(false)
  }

  // Not sure of the reccommended approach for this sort of thing in React
  const handleTextFieldChange = (e, {
    id = 0,
    model = null,
    type = null,
    key = null,
    property = null,
  }) => {
    const value = e.target.value

    if (model === 'meta') {
      const _meta = metaById(id) || { id: 0, active: true, project: 'Default' }

      if (property) {
        if (!_meta[key]) _meta[key] = {}
        _meta[key][property] = value
      } else {
        _meta[key] = value
      }

      setMeta(_meta)
    }
    if (model === 'action') {
      const _action = actionByIdAndType({ id, type }) || { id: 0, active: true, type }

      if (key === 'label') _action['label'] = value

      if (property) {
        if (!_action[key]) _action[key] = {}
        _action[key][property] = value
      } else {
        _action[key] = value
      }

      setAction(_action)
    }
  }

  // const [modelType, setModelType] = useState(false)

  // const handleRadioChange = (event) => {
  //   const value = event.target.value
  //   setModelType(value ? value : 'url');
  // };

  // run once
  useEffect(() => {
    getSettings()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="Menu Settings"
      open={open}
      className="SettingsDialog"
      fullWidth
      maxWidth='sm'
    >
      {(loading || saving) &&
        <div style={{ position: 'absolute', top: 0, width: '100%' }}>
          <LinearProgress />
        </div>
      }
      <DialogTitle id="simple-dialog-title">Settings</DialogTitle>
      <DialogContent>
        <Accordion square>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="meta-content"
            id="meta-header"
          >
            <Typography>Meta</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {loading
              ? <CircularProgress></CircularProgress>
              : <TextField
                label="Connection Logo URL"
                onChange={e => handleTextFieldChange(e, {
                  model: 'meta',
                  id: activeMeta?.id || 0,
                  key: 'logo',
                })}
                defaultValue={activeMeta?.logo}
                variant="outlined"
                fullWidth
              />
            }
          </AccordionDetails>
        </Accordion>

        {/* TODO: Abstract out into reusable component */}
        <Accordion square>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="Models-content"
            id="Models-header"
          >
            <Typography>Models</Typography>
          </AccordionSummary>
          {models.map(action => (
            <Accordion square w={1} key={action.id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`action-${action.id}-content`}
                id={`action-${action.id}-header`}
              >
                {/* TODO: toggle action active property
                <FormControlLabel
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}
                  label={action.label}
                  control={<Switch color="primary" />}
                /> */}
                <Typography>{action.label}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box display="flex" flexDirection="row" flexWrap="wrap">
                  <Box mr={2}>
                    <TextField
                      label="Label"
                      onChange={e => handleTextFieldChange(e, {
                        model: 'action',
                        type: 'model',
                        id: action.id,
                        key: 'label',
                      })}
                      defaultValue={action.label}
                      variant="outlined"
                      fullWidth
                    />
                  </Box>
                  <Box mr={2}>
                    <TextField
                      label=".glb URL"
                      onChange={e => handleTextFieldChange(e, {
                        model: 'action',
                        type: 'model',
                        key: 'config',
                        property: 'url',
                      })}
                      defaultValue={action.config?.url}
                      variant="outlined"
                      fullWidth
                    />
                  </Box>
                  <Box mr={2}>
                    <TextField
                      label=".usdz URL"
                      onChange={e => handleTextFieldChange(e, {
                        model: 'action',
                        type: 'model',
                        key: 'config',
                        property: 'iosUrl',
                      })}
                      defaultValue={action.config?.iosUrl}
                      variant="outlined"
                      fullWidth
                    />
                  </Box>
                  <Box my={2}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={e => handleRemoveClick(action)}
                      disabled={saving}
                      startIcon={<DeleteIcon />}
                    >
                      Remove
                    </Button>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
          <Accordion square>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`action-new-model-content`}
              id={`action-new-model-header`}
            >
              <Typography>Create New Model</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Box>
                {/* <Box mb={2}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">New Model Source Type:</FormLabel>
                    <RadioGroup row aria-label="gender" name="gender1" value={modelType} onChange={e => handleRadioChange(e)} >
                      <FormControlLabel value="url" control={<Radio />} label="URL" />
                      <FormControlLabel value="s3" control={<Radio />} label="S3" />
                      <FormControlLabel value="upload" control={<Radio />} label="Upload" />
                    </RadioGroup>
                  </FormControl>
                </Box> */}
                <Box mb={2}>
                  <TextField
                    label="Label"
                    onChange={e => handleTextFieldChange(e, {
                      model: 'action',
                      type: 'model',
                      key: 'label',
                    })}
                    variant="outlined"
                    fullWidth
                  />
                  <Box mb={2}>
                    <TextField
                      label=".glb URL"
                      onChange={e => handleTextFieldChange(e, {
                        model: 'action',
                        type: 'model',
                        key: 'config',
                        property: 'url',
                      })}
                      variant="outlined"
                      fullWidth
                    />
                    <TextField
                      label=".usdz URL"
                      onChange={e => handleTextFieldChange(e, {
                        model: 'action',
                        type: 'model',
                        key: 'config',
                        property: 'iosUrl',
                      })}
                      variant="outlined"
                      fullWidth
                    />
                  </Box>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Accordion>

        {/* Images */}
        <Accordion square>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="images-content"
            id="images-header"
          >
            <Typography>Images</Typography>
          </AccordionSummary>
          {images.map(action => (
            <Accordion square w={1} key={action.id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`action-${action.id}-content`}
                id={`action-${action.id}-header`}
              >
                <Typography>{action.label}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box display="flex" flexDirection="row" flexWrap="wrap">
                  <Box mr={2}>
                    <TextField
                      label="Label"
                      onChange={e => handleTextFieldChange(e, {
                        model: 'action',
                        type: 'image',
                        id: action.id,
                        key: 'label',
                      })}
                      defaultValue={action.label}
                      variant="outlined"
                      fullWidth
                    />
                  </Box>
                  <Box mr={2}>
                    <TextField
                      label="Image URL"
                      onChange={e => handleTextFieldChange(e, {
                        model: 'action',
                        type: 'image',
                        id: action.id,
                        key: 'config',
                        property: 'url',
                      })}
                      defaultValue={action.config?.url}
                      variant="outlined"
                      fullWidth
                    />
                  </Box>
                  <Box my={2}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={e => handleRemoveClick(action)}
                      disabled={saving}
                      startIcon={<DeleteIcon />}
                    >
                      Remove
                  </Button>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
          <Accordion square>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`action-new-video-content`}
              id={`action-new-video-header`}
            >
              <Typography>Create New Image</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                label="Image Label"
                onChange={e => handleTextFieldChange(e, {
                  model: 'action',
                  type: 'image',
                  key: 'label',
                })}
                variant="outlined"
                fullWidth
              />
              <TextField
                label="Image URL"
                onChange={e => handleTextFieldChange(e, {
                  model: 'action',
                  type: 'image',
                  key: 'config',
                  property: 'url',
                })}
                variant="outlined"
                fullWidth
              />
            </AccordionDetails>
          </Accordion>
        </Accordion>

        <Accordion square>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="Videos-content"
            id="Videos-header"
          >
            <Typography>Videos</Typography>
          </AccordionSummary>
          {videos.map(action => (
            <Accordion square w={1} key={action.id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`action-${action.id}-content`}
                id={`action-${action.id}-header`}
              >
                {/* TODO: toggle action active property
                <FormControlLabel
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}
                  label={action.label}
                  control={<Switch color="primary" />}
                /> */}
                <Typography>{action.label}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box display="flex" flexDirection="row" flexWrap="wrap">
                  <Box mr={2}>
                    <TextField
                      label="Label"
                      onChange={e => handleTextFieldChange(e, {
                        model: 'action',
                        type: 'video',
                        id: action.id,
                        key: 'label',
                      })}
                      defaultValue={action.label}
                      variant="outlined"
                      fullWidth
                    />
                  </Box>
                  <Box mr={2}>
                    <TextField
                      label="Video URL"
                      onChange={e => handleTextFieldChange(e, {
                        model: 'action',
                        type: 'video',
                        id: action.id,
                        key: 'config',
                        property: 'url',
                      })}
                      defaultValue={action.config?.url}
                      variant="outlined"
                      fullWidth
                    />
                  </Box>
                  <Box my={2}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={e => handleRemoveClick(action)}
                      disabled={saving}
                      startIcon={<DeleteIcon />}
                    >
                      Remove
                  </Button>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
          <Accordion square>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`action-new-video-content`}
              id={`action-new-video-header`}
            >
              <Typography>Create New Video</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                label="Label"
                onChange={e => handleTextFieldChange(e, {
                  model: 'action',
                  type: 'video',
                  key: 'label',
                })}
                variant="outlined"
                fullWidth
              />
              <TextField
                label="Video URL"
                onChange={e => handleTextFieldChange(e, {
                  model: 'action',
                  type: 'video',
                  key: 'config',
                  property: 'url',
                })}
                variant="outlined"
                fullWidth
              />
            </AccordionDetails>
          </Accordion>
        </Accordion>

        <Accordion square>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="action-new-animation-content"
            id="action-new-animation-header"
          >
            <Typography>Animations</Typography>
          </AccordionSummary>
          {animations.map(action => (
            <Accordion square w={1} key={action.id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`action-${action.id}-content`}
                id={`action-${action.id}-header`}
              >
                <Typography>{action.label}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TextField
                  label="Label"
                  onChange={e => handleTextFieldChange(e, {
                    model: 'action',
                    type: 'animation',
                    id: action.id,
                    key: 'label',
                  })}
                  defaultValue={action.label}
                  variant="outlined"
                  fullWidth
                />
                <TextField
                  label="Number of Phases"
                  onChange={e => handleTextFieldChange(e, {
                    model: 'action',
                    type: 'animation',
                    id: action.id,
                    key: 'config',
                    property: 'numberOfPhases',
                  })}
                  defaultValue={action.config?.numberOfPhases || 0}
                  variant="outlined"
                  fullWidth
                />
              </AccordionDetails>
            </Accordion>
          ))}
          <Accordion square>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`action-new-animation-content`}
              id={`action-new-animation-header`}
            >
              <Typography>Create New Animation</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                label="Label"
                onChange={e => handleTextFieldChange(e, {
                  model: 'action',
                  type: 'animation',
                  key: 'label',
                })}
                variant="outlined"
                fullWidth
              />
              <TextField
                label="Number of Phases"
                onChange={e => handleTextFieldChange(e, {
                  model: 'action',
                  type: 'animation',
                  key: 'config',
                  property: 'numberOfPhases',
                })}
                variant="outlined"
                fullWidth
              />
            </AccordionDetails>
          </Accordion>
        </Accordion>

        <Box my={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveClick}
            disabled={saving || !changed}
            startIcon={<SaveIcon />}
          >
            Save
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default SettingsDialog
