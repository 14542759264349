const watchers = {};

const deleteWatcher = id => {
  if (watchers[id]) {
    if (watchers[id].peerConnection) {
      watchers[id].peerConnection.close();
      watchers[id].peerConnection.onicecandidate = null;
      delete watchers[id].peerConnection;
    }
    delete watchers[id];
  }
}

const deleteWatchers = () => {
  Object.keys(watchers).forEach(id => deleteWatcher(id))
}

export {
  watchers,
  deleteWatcher,
  deleteWatchers
};