import React from 'react'
import socket from "../services/socket";
import ConnectionStatusSnackbar from './ConnectionStatusSnackbar';
import MessageSnackbar from './MessageSnackbar';
import HostView from './HostView';
import PeerView from './PeerView';
import JumbotronView from './JumbotronView/index';
const ZAMBONI_START_STATE = require("../games/Zamboni").ZAMBONI_START_STATE;
class WebSocketPlayground extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      profile: {
        userId: null,
        roomId: null,
        host: window.location.pathname.includes('host')
      },
      connected: false,
      renderStreamBroadcaster: false,
      input: 'Hello there',
      message: '',
      component: {
        type: '',
        config: {}
      },
      gameState: ZAMBONI_START_STATE,
      gameData: {
        // TEMP
        assignedTeamIndex: null,
        countdownTimer: null,
        gameTimes: null,
        scoresProgressFloat: null,
        teamPlaces: null
      }
    }
  }

  componentDidMount() {
    socket.on('profile', data => {
      this.setState({profile: JSON.parse(data)})
    })

    socket.on('message', data => {
      this.setState({message: data.message})
    })

    socket.on('component', data => {
      this.setState({component: data.component})
    })

    socket.on('disconnect', () => {
      this.setState({connected: false})
    })

    socket.on('connect', () => {
      this.setState({connected: true})
    })

    socket.on('connect_error', () => {
      this.setState({connected: false})
    })

    socket.on('gameStateChange', data => {
      this.setState({gameState: data.value})
    })

    socket.on('gameDataChange', data => {
      // console.info(data)
      this.setState({gameData: {...this.state.gameData,  ...data}})
    })

    socket.on('unassign_from_team', data => {
      this.setState({gameData: {...this.state.gameData,  ...{
        assignedTeamIndex: null
      }}})
    })

    if (window.location.pathname.includes('jumbotron')) {
      socket.emit('message', JSON.stringify({
        type: 'joinRoom',
        roomId: 'jumbotron'
      }))
      socket.emit('message', JSON.stringify({
        type: 'zamboniInit',
        mode: 'jumbotron'
      }))
    } else if (!window.location.pathname.includes('host')) {
      socket.emit('message', JSON.stringify({
        type: 'joinRoom',
        roomId: 'clients'
      }))
      socket.emit('message', JSON.stringify({
        type: 'zamboniInit',
        mode: 'player'
      }))
    }
  }

  render() {
    let component  = 'PeerView';
    if (window.location.pathname.includes('host')) component = 'HostView';
    if (window.location.pathname.includes('jumbotron')) component = 'JumbotronView';

    return (
      <div className="WebSocketPlayground">
        <ConnectionStatusSnackbar/>
        <MessageSnackbar/>
        {component === 'HostView' && <HostView
          component={this.state.component}
          gameState={this.state.gameState}
          meta={this.props.meta}
          setMeta={this.props.setMeta}
        />}
        {component === 'JumbotronView' &&<JumbotronView
          config={this.state.component.config}
          gameState={this.state.gameState}
          gameData={this.state.gameData}
        />}
        {component === 'PeerView' && <PeerView
          component={this.state.component}
          connected={this.state.connected}
          gameState={this.state.gameState}
          gameData={this.state.gameData}
          meta={this.props.meta}
          renderStreamBroadcaster={this.state.renderStreamBroadcaster}
        />}
      </div>
    )
  }
}

export default WebSocketPlayground
