import React from 'react'
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
// import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import socket from "../services/socket";
import axios from 'axios'

class StreamWatcher extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      audio: false,
      lights: props.config.lights ? true : false
    }

    this.peerConnection = null;
    this.video = React.createRef();

    this.ontrack = this.ontrack.bind(this);
    this.reset = this.reset.bind(this);
    this.deletePeerConnection = this.deletePeerConnection.bind(this);

    this.toggleAudio = this.toggleAudio.bind(this);
    this.toggleLights = this.toggleLights.bind(this);
    this.enableFullScreen = this.enableFullScreen.bind(this);
  }

  componentDidMount() {
    // const server_address ='v5g-turn-server.parado.cz';
    const server_address ='44.201.86.41:3478';
    const config = {
      iceServers: [
        {
          "urls": "stun:stun.l.google.com:19302",
        },
        {
          "urls": `turn:${server_address}`,
          "username": "poc_user",
          "credential": "2Z51Tlak^V4#ZeyM%"
        },
      ]
    };

    socket.on("offer", async (id, description) => {
      if (!this.peerConnection) {
        this.peerConnection = new RTCPeerConnection(config);

        this.peerConnection.ontrack = this.ontrack

        this.peerConnection.onicecandidate = ({ candidate }) => {
          if (candidate) {
            socket.emit("candidate", id, candidate);
          }
        };

        this.peerConnection.onnegotiationneeded = async () => {
          const offer = await this.peerConnection.createOffer();

          if (this.peerConnection.signalingState !== "stable") return;

          await this.peerConnection.setLocalDescription(offer);
          socket.emit("offer", id, this.peerConnection.localDescription);
        }
      }

      if (description && this.peerConnection.signalingState !== "stable") {
        await Promise.all([
          await this.peerConnection.setLocalDescription({type: "rollback"}),
          await this.peerConnection.setRemoteDescription(description)
        ])
      } else {
        await this.peerConnection.setRemoteDescription(description)
      }

      await this.peerConnection.setLocalDescription(await this.peerConnection.createAnswer());

      socket.emit("answer", id, this.peerConnection.localDescription);
    });

    socket.on("candidate", async (id, candidate) => {
      if (candidate) {
        try {
          await this.peerConnection.addIceCandidate(new RTCIceCandidate(candidate))
        }
        catch (error) {
          console.error(error.response.data)
        }
      }
    });

    if (socket.connected) {
      this.emitWatcher();
    }

    socket.on("connect", () => {
      this.emitWatcher()
    });

    socket.on("broadcaster", () => {
      this.emitWatcher()
    });
  }

  componentWillUnmount() {
    this.reset()
  }

  ontrack(event) {
    if(this.video.current) this.video.current.srcObject = event.streams[0];
  }

  emitWatcher() {
    socket.emit("watcher");
  }

  deletePeerConnection() {
    if (this.peerConnection) {
      this.peerConnection.onicecandidate = null
      this.peerConnection.onnegotiationneeded = null
      this.peerConnection.ontrack = null
      this.peerConnection.onaddstream = null

      if(this.video.current) this.video.current.srcObject = null;

      this.peerConnection.close()
      this.peerConnection = null;
    }
  }

  reset() {
    socket.removeAllListeners("offer")
    socket.removeAllListeners("broadcaster")
    socket.removeAllListeners("candidate")
    socket.removeListener("connect", this.emitWatcher)

    this.deletePeerConnection()
  }

  toggleLights() {
    axios.get('https://maker.ifttt.com/trigger/andy_light_toggle/with/key/byqUfXch98A-gIDFVpaBu-')
      .then((response) => {
        console.log(response);
      }).catch (function (error) {
        console.log(error);
      })
  }

  toggleAudio() {
    this.video.current.muted = this.state.audio
    if(this.video.current) {
      this.setState({
        audio: !this.state.audio
      })
    }
  }

  enableFullScreen() {
    if(this.video.current) {
      if (this.video.current.webkitEnterFullScreen) {
          this.video.current.webkitEnterFullScreen()
      } else {
          this.video.current.requestFullscreen()
      }
    }
  }

  render() {
    let audioButton;

    if (this.state.audio) {
      audioButton = <VolumeUpIcon color="primary"/>
    } else {
      audioButton = <VolumeOffIcon color="primary"/>
    }

    return (
      <Box my={2} className="StreamWatcher video-outer">
        <div className="video-inner">
          <video className={this.props.config.rotate ? 'rotate rotate--' + this.props.config.rotate : '' } playsInline autoPlay muted ref={this.video}></video>
          <div className='controls active'>
            <IconButton aria-label="audio" onClick={this.toggleAudio}>
              {audioButton}
            </IconButton>
            <IconButton aria-label="fullscreen" onClick={this.enableFullScreen}>
              <FullscreenIcon color="primary"/>
            </IconButton>
          </div>
        </div>
      </Box>
    )
  }
}

export default StreamWatcher
