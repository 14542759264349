import io from 'socket.io-client'
require('dotenv').config()

let endpoint = 'https://whtvr.app'

if (process.env.REACT_APP_NODE_ENV === 'development') {
  const PORT = process.env.REACT_APP_SERVER_PORT || 3003
  endpoint = `http://localhost:${PORT}/`
}

const query = {}
const hash = window.location.pathname.split('/')[1]
if (hash) query.hash = hash

const socket = io(endpoint, { query })

window.onunload = window.onbeforeunload = () => {
  if(socket) socket.close();
};

export default socket
