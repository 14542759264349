import icon_hamburger from '../assets/imgs/icon-hamburger.png'          // 0
// import icon_tap from '../assets/imgs/icon-tap.svg'                   // 1
import icon_tap from '../assets/imgs/icon-tap.gif'                      // 1
import logo_blues from '../assets/imgs/logo-blues.svg'                  // 2
import logo_dobbs from '../assets/imgs/logo-dobbs.png'                  // 3
import tap_button from '../assets/imgs/tap-button.svg'                  // 4
import win_team1 from '../assets/imgs/win-team1.png'                    // 5
import win_team2 from '../assets/imgs/win-team2.png'                    // 6
import win_team3 from '../assets/imgs/win-team3.png'                    // 7
import zam_blue from '../assets/imgs/zam-blue.png'                      // 8
import zam_red from '../assets/imgs/zam-red.png'                        // 9
import zam_tan from '../assets/imgs/zam-tan.png'                        // 10


const imgs = [
    icon_hamburger,
    icon_tap,
    logo_blues,
    logo_dobbs,
    tap_button,
    win_team1,
    win_team2,
    win_team3,
    zam_blue,
    zam_red,
    zam_tan,

]
export default imgs
