import React from 'react'
import Box from '@material-ui/core/Box';

function MediaServerStreamWatcher() {
  return (
    <Box my={2} className="MediaServerStreamWatcher video-outer">
      <h1>Media Server Stream Watcher</h1>
      <iframe width="560" height="315" title="antMediaEmbed" src="https://evernorth-demo-antmedia-server.parado.cz:5443/Default/play.html?name=1" frameBorder="0" allowFullScreen></iframe>
    </Box>
  )
}

export default MediaServerStreamWatcher
