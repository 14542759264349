const gameShouldEndBasedOnScore = (scores) => {
  return scores.some((score) => score > 100);
};

const getScoresProgressFloatFromScores = (scores) => {
  return scores.map((score) => score / 100);
};

export {
  gameShouldEndBasedOnScore,
  getScoresProgressFloatFromScores,
}
