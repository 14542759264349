import imgs from '../../helpers/imgs'
import './stylesheets/styleguide.scss'
import './stylesheets/Nav.scss'

function Nav(props) {

  return(
    <nav className="Nav">
        <div className='nav-container'>
            <img className='bluesLogo' src={imgs[2]} alt="St. Louis Blues Logo: blue music note" />
        </div>
        <img className='ham' src={imgs[0]} alt="yellow hamburger icon" />
    </nav>
  )
}

export default Nav;
