import React, { useState } from 'react';
import './stylesheets/styleguide.scss'
import './stylesheets/Leaderboard.scss'

function Leaderboard(props) {
  const [viewBoard, setViewBoard] = useState(false);
  let triviaRecord = props.config.gameData.triviaRecord
  let triviaRecords = props.config.gameData.triviaRecords

  // // mock
  // triviaRecord = {
  //   "name": "lorem ipsum",
  //   "correctAnswers": 3
  // }

  // triviaRecords = {
  //     "a": {
  //         "name": "lorem ipsum",
  //         "correctAnswers": 0
  //     },
  //     "b": {
  //         "name": "lorem ipsum",
  //         "correctAnswers": 3
  //     },
  //     "c": {
  //         "name": "lorem ipsum",
  //         "correctAnswers": 2
  //     },
  //     "d": {
  //         "name": "lorem ipsum",
  //         "correctAnswers": 1
  //     },
  //     "e": {
  //         "name": "lorem ipsum",
  //         "correctAnswers": 1
  //     },
  //     "f": {
  //         "name": "lorem ipsum",
  //         "correctAnswers": 3
  //     },
  //     "g": {
  //         "name": "lorem ipsum",
  //         "correctAnswers": 2
  //     },
  //     "h": {
  //         "name": "lorem ipsum",
  //         "correctAnswers": 0
  //     },
  //     "i": {
  //         "name": "lorem ipsum",
  //         "correctAnswers": 1
  //     },
  //     "j": {
  //         "name": "lorem ipsum",
  //         "correctAnswers": 1
  //     },
  //     "k": {
  //         "name": "lorem ipsum",
  //         "correctAnswers": 3
  //     },
  //     "l": {
  //         "name": "lorem ipsum",
  //         "correctAnswers": 2
  //     },
  //     "m": {
  //         "name": "lorem ipsum",
  //         "correctAnswers": 0
  //     },
  // }

  return(
  <div className="leaderboard main-content">
    <div className="mask"></div>
    { triviaRecord && triviaRecord.correctAnswers === props.config.gameData.totalQuestions && !viewBoard &&
      <div className="congrats">
        <div className="labels">
          <p>{triviaRecord.correctAnswers} out of {props.config.gameData.totalQuestions} correct</p>
        </div>
        <div className="progress">
          <div className="bar" style={{width: '100%'}}></div>
        </div>
        <h1>Impressive!</h1>
        <p className="details">
          <span className="txt--success">Cheers to you, a true Bluenatic! </span>
          You scored a perfect 5 out of 5. Head to the leaderboard to see how the rest of the crowd stacked up. Let’s Go Blues!
        </p>
        <h5 className="cta" onClick={() => setViewBoard(true)}>View Leaderboard</h5>
      </div>
    }
    { ((triviaRecord && triviaRecord.correctAnswers !== props.config.gameData.totalQuestions) || !triviaRecord || viewBoard) &&
      <div className="board">
        <h1>Leaderboard</h1>
        <div className="records-wrapper">
          <div className="records">
            { Object.values(triviaRecords)
              .sort((a, b) => (a.correctAnswers < b.correctAnswers) ? 1 : -1)
              .map((record, index) => (
              <div className={`record ${record.correctAnswers === props.config.gameData.totalQuestions ? 'txt--success' : ''}`} key={index}>
                <div className="labels">
                  <p>{ record.name ? record.name : '###'}</p>
                  <p>{ record.correctAnswers ? record.correctAnswers : 0}/{props.config.gameData.totalQuestions}</p>
                </div>
                <div className="progress">
                  <div className="bar" style={{width: `${(record.correctAnswers / props.config.gameData.totalQuestions) * 100}%`}}></div>
                </div>
              </div>
            ))}
          </div>
        </div>
        { triviaRecord && triviaRecord.correctAnswers === props.config.gameData.totalQuestions &&
          <h5 className="txt--gold-rich cta">Redeem Prize</h5>
        }
      </div>
    }
  </div>
  )
}

export default Leaderboard;
