import {getZamboniImageFromTeamIndex} from '../../helpers/zamboni'
import {gsap} from "gsap";
import * as React from "react";
import ZamboniPodium from '../../assets/zamboni-podium.png'

class JumboPostGame extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      teamPlaces: []
    }
  }

  componentDidMount() {
    console.log("componentDidMount jumbo-post-view", this.props, this.props.config)
    if (!this.state.teamPlaces.length && this.props.config.gameData.teamPlaces) {
      this.setState({ teamPlaces: this.props.config.gameData.teamPlaces });
    }
    gsap.to(".jumbotron-view", {
      "background-size": "700%",
      "background-position": "top 5% center",
      duration: 2,
      onComplete: () => {
        const jumbotronPostGame = document.querySelector(".jumbo-post-game")
        if (jumbotronPostGame) jumbotronPostGame.style.display = "block";
      }
    })
  }

  componentDidUpdate() {
    if (!this.state.teamPlaces.length && this.props.config.gameData.teamPlaces) {
      this.setState({ teamPlaces: this.props.config.gameData.teamPlaces });
    }
  }

  componentWillUnmount() {

  }

  render() {
    return(
      <div className={"jumbo-post-game"} style={{display: "none"}}>
        <div className="inner">
          {this.state.teamPlaces.length ? <h1 className="title">TEAM {this.state.teamPlaces[0] + 1} WINS!</h1> : ''}
          {this.state.teamPlaces.length ? this.state.teamPlaces.map((teamIndex, place) => (
            <img
              key={teamIndex}
              className={'team-zamboni place-' + (place + 1)}
              src={getZamboniImageFromTeamIndex(teamIndex)}
              alt="zamboni"
            />
          )) : ''}
          <img className="zamboni-podium" src={ZamboniPodium} alt="display-only"/>
        </div>
      </div>
    )
  }
}

export default JumboPostGame;
