import React from 'react'
import socket from "../services/socket";

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import SendIcon from '@material-ui/icons/Send';

class MessageInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      message: '',
    }

    this.handleInput = this.handleInput.bind(this);
    this.messageAll = this.messageAll.bind(this);
    this.messageRoom = this.messageRoom.bind(this);
    this.messageUsers = this.messageUsers.bind(this);
  }

  handleClose() {
    this.setState({
      open: false
    })
  }

  handleInput(event) {
    this.setState({message: event.target.value});
  }

  messageAll() {
    socket.emit('message', JSON.stringify({
      type: 'message',
      target: 'all',
      message: this.state.message
    }))
  }

  messageRoom() {
    socket.emit('message', JSON.stringify({
      type: 'message',
      target: 'room',
      message: this.state.message
    }))
  }

  messageUsers(userIds = []) {
    socket.emit('message', JSON.stringify({
      type: 'message',
      target: 'users',
      userIds,
      message: this.state.message
    }))
  }

  render() {
    return (
      <Box position="relative" width={1}>
        <TextField type="text" variant="outlined" fullWidth
          value={this.state.input}
          onChange={this.handleInput}
        />
        <Box position="absolute" top="6px" right="0">
          <IconButton
            color="primary"
            aria-label="send"
            onClick={this.messageAll}
          >
            <SendIcon/>
          </IconButton>
        </Box>
      </Box>
    )
  }
}

export default MessageInput
