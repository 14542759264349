import React from 'react'
import socket from "../services/socket";
import { watchers, deleteWatcher } from "../services/watchers";
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import FullscreenIcon from '@material-ui/icons/Fullscreen';

class StreamBroadcaster extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      audioSource: '',
      audioOptions: [
        {
          value: 'none',
          text: 'Muted (No Audio)'
        }
      ],
      videoSource: '',
      videoOptions: [],
      isStreaming: false,
      peerConnectionCount: 0,
    }

    this.stream = null;

    this.video = React.createRef();
    this.audioSelect = React.createRef();
    this.videoSelect = React.createRef();

    this.openBroadcast = this.openBroadcast.bind(this);
    this.closeBroadcast = this.closeBroadcast.bind(this);
    this.beginStream = this.beginStream.bind(this);
    this.getDevices = this.getDevices.bind(this);
    this.getStream = this.getStream.bind(this);
    this.removePeerConnection = this.removePeerConnection.bind(this);
    this.removePeerConnections = this.removePeerConnections.bind(this);
    this.endStream = this.endStream.bind(this);
    this.onAudioSourceChange = this.onAudioSourceChange.bind(this);
    this.onVideoSourceChange = this.onVideoSourceChange.bind(this);
    this.toggleStreaming = this.toggleStreaming.bind(this);
    this.enableFullScreen = this.enableFullScreen.bind(this);
  }

  async componentDidMount() {
    await this.getDevices()
    await this.getStream()

    socket.on('component', data => {
      if (data.component.type !== 'StreamWatcher') {
        this.closeBroadcast()
      }
      else {
        if (this.state.isStreaming) {
          this.openBroadcast()
        }
      }
    })
  }

  componentWillUnmount() {
    this.closeBroadcast()
  }

  openBroadcast() {
    // const server_address ='v5g-turn-server.parado.cz';
    const server_address ='44.201.86.41:3478';
    const config = {
      iceServers: [
        {
          "urls": "stun:stun.l.google.com:19302",
        },
        {
          "urls": `turn:${server_address}`,
          "username": "poc_user",
          "credential": "2Z51Tlak^V4#ZeyM%"
        }
      ]
    };

    socket.on("answer", async (id, description) => {
      if (description && watchers[id] && watchers[id].peerConnection) {
        if (watchers[id].peerConnection['signalingState'] !== 'stable') {
          await watchers[id].peerConnection.setRemoteDescription(description);
        }
      }
    });

    socket.on("watcher", id => {
      if (!watchers[id]) {
        watchers[id] = {}
        watchers[id].peerConnection = new RTCPeerConnection(config);

        this.stream.getTracks().forEach(track => {
          watchers[id][track.kind] = watchers[id].peerConnection.addTrack(track, this.stream);
        });

        watchers[id].peerConnection.onicecandidate = event => {
          if (event.candidate) {
            socket.emit("candidate", id, event.candidate);
          }
        };

        watchers[id].peerConnection.onnegotiationneeded = async () => {
          const offer = await watchers[id].peerConnection.createOffer();

          if (watchers[id].peerConnection.signalingState !== "stable") return;
          await watchers[id].peerConnection.setLocalDescription(offer);
          socket.emit("offer", id, watchers[id].peerConnection.localDescription);

          this.setState({peerConnectionCount: Object.entries(watchers).length})
        }
      }
    });

    socket.on("candidate", async (id, candidate) => {
      if (candidate) {
        try {
          await watchers[id].peerConnection.addIceCandidate(new RTCIceCandidate(candidate))
        }
        catch (error) {
          console.error(error)
        }
      }
    });

    socket.on("disconnectPeer", id => this.removePeerConnection(id));

    socket.emit("broadcaster");
  }

  closeBroadcast() {
    socket.removeAllListeners("answer")
    socket.removeAllListeners("watcher")
    socket.removeAllListeners("candidate")
    socket.removeAllListeners("disconnectPeer")
    this.removePeerConnections()
  }

  async getDevices() {
    await navigator.mediaDevices.getUserMedia({audio: true, video: true});
    const deviceInfos = await navigator.mediaDevices.enumerateDevices();

    for (const deviceInfo of deviceInfos) {
      const option = {
        value: deviceInfo.deviceId,
        text: 'default'
      }
      if (deviceInfo.kind === "audioinput") {
        option.text = deviceInfo.label || `Microphone ${this.audioSelect.current.length + 1}`;
        this.setState({audioOptions: [ option, ...this.state.audioOptions]});
      } else if (deviceInfo.kind === "videoinput") {
        option.text = deviceInfo.label || `Camera ${this.videoSelect.current.length + 1}`;
        this.setState({videoOptions: [ option, ...this.state.videoOptions]});
      }
    }
  }

  async getStream() {
    const constraints = {}

    constraints.video = {
        deviceId: this.state.videoSource ? { exact: this.state.videoSource } : undefined,
        // width: { min: 1280 },
        // height: { min: 720 }
    }

    // On initial run, attach audio to stream
    // On subsequent runs, only fetch audio if not muted
    if (
        !this.state.audioSource ||
        (this.stream && this.stream.getAudioTracks()[0] && this.stream.getAudioTracks()[0].enabled)
      ) {
      constraints.audio = {
        deviceId: this.state.audioSource ? { exact: this.state.audioSource } : undefined
      }
    }

    this.stream = await navigator.mediaDevices.getUserMedia(constraints);

    if (!this.state.audioSource) {
      // Default to muted
      this.stream.getAudioTracks()[0].enabled = false
      this.setState({ audioSource: 'none' })
    }
    if (!this.state.videoSource) {
      const videoSource = this.state.videoOptions.find(
        option => option.text === this.stream.getVideoTracks()[0].label
      );

      this.setState({ videoSource: videoSource.value })
    }

    this.video.current.srcObject = this.stream;

    Object.values(watchers).forEach(watcher => {
      this.stream.getTracks().forEach(track => {
        watcher[track.kind].replaceTrack(track, this.stream)
      });
    })
  }

  removePeerConnection(id) {
    deleteWatcher(id)

    this.setState({peerConnectionCount: Object.entries(watchers).length})
  }

  removePeerConnections() {
    Object.entries(watchers).forEach(([key, value]) => {
      this.removePeerConnection(key)
    })
  }

  onAudioSourceChange(event) {
    this.setState({audioSource: event.target.value}, () => {
      if (this.state.audioSource === 'none') {
        this.stream.getAudioTracks()[0].enabled = false
      } else {
        this.stream.getAudioTracks()[0].enabled = true
        this.getStream()
      }
    });
  }

  onVideoSourceChange(event) {
    this.setState({videoSource: event.target.value}, () => {
      this.getStream()
    });
  }

  enableFullScreen() {
    if(this.video.current) {
      if (this.video.current.webkitEnterFullScreen) {
        this.video.current.webkitEnterFullScreen()
      } else {
        this.video.current.requestFullscreen()
      }
    }
  }

  beginStream() {
    this.openBroadcast()
  }

  endStream() {
    this.closeBroadcast()
  }

  sendComponent(type = '', config = {}) {
    socket.emit('message', JSON.stringify({
      type: 'component',
      target: 'all',
      component: {
        type,
        config
      }
    }))
  }

  toggleStreaming() {
    const isStreaming = !this.state.isStreaming
    this.setState({
      isStreaming
    })

    if (isStreaming) {
      this.openBroadcast()
    }
    else {
      this.closeBroadcast()
    }
  }

  render() {
    const IOSSwitch = withStyles((theme) => ({
      root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: theme.spacing(1),
      },
      switchBase: {
        padding: 1,
        '&$checked': {
          transform: 'translateX(16px)',
          color: theme.palette.common.white,
          '& + $track': {
            backgroundColor: '#52d869',
            opacity: 1,
            border: 'none',
          },
        },
        '&$focusVisible $thumb': {
          color: '#52d869',
          border: '6px solid #fff',
        },
      },
      thumb: {
        width: 24,
        height: 24,
      },
      track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
      },
      checked: {},
      focusVisible: {},
    }))(({ classes, ...props }) => {
      return (
        <Switch
          focusVisibleClassName={classes.focusVisible}
          disableRipple
          classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
          }}
          {...props}
        />
      );
    });

    return (
      <Box className="StreamBroadcaster" width={1}>
        <Box my={2} className="video-outer">
          <div className="video-inner">
            <video playsInline autoPlay muted ref={this.video}></video>
            <div className='controls active'>
              <div></div>
              <IconButton aria-label="fullscreen" onClick={this.enableFullScreen}>
                <FullscreenIcon color="primary"/>
              </IconButton>
            </div>
          </div>
        </Box>
        <Box my={2}>
          <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
            <FormControl>
              <Select
                value={this.state.audioSource}
                onChange={this.onAudioSourceChange}
              >
                {this.state.audioOptions.map(({value, text}, index) => {
                  return <MenuItem key={index} value={value}>{text}</MenuItem>
                })}
              </Select>
            </FormControl>

            <FormControl>
              <Select
                value={this.state.videoSource}
                onChange={this.onVideoSourceChange}
              >
                {this.state.videoOptions.map(({value, text}, index) => {
                  return <MenuItem key={index} value={value}>{text}</MenuItem>
                })}
              </Select>
            </FormControl>
          </Grid>
        </Box>
        <Box my={2}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <FormControlLabel
              control={<IOSSwitch checked={this.state.isStreaming} onChange={this.toggleStreaming}/>}
              label="Stream"
            />
            <Typography>
              Peer Connections: {this.state.peerConnectionCount}
            </Typography>
          </Grid>
        </Box>
        <Box my={2}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
          </Grid>
        </Box>
      </Box>
    )
  }
}

export default StreamBroadcaster
