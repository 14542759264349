import React from 'react'
import ReactPlayer from 'react-player'
require('dotenv').config()

class VideoPlayer extends React.Component {
  constructor(props) {
    super(props)
    this.state = { playing: false };
  }

  handleOnReady = () => {
    if (this.props.config && this.props.config.autoplay) {
      setTimeout(() => this.setState({ playing: this.props.config.autoplay }), 100);
    }
  }

  render() {
    const autoPlayValue = this.props.config.autoplay ? 1 : 0;

    return (
      <div className="VideoPlayer">
        <ReactPlayer
          playsInline
          controls
          playing={this.state.playing}
          // autoplay={this.state.playing}
          onReady={this.handleOnReady}
          // light="true"
          url={this.props.config && this.props.config.url ? this.props.config.url : ''}
          config={{
            youtube: {
              playerVars: {
                showinfo: 0,
                autoplay: autoPlayValue
              },
            },
            vimeo: {
              controls: true,
              playsinline: true,
              responsive: true,
            },
            facebook: {
              appId: '12345'
            },
            file: {
              attributes: {
                autoplay: true,
                playsInline: true,
                controls: false,
                loop: true
              }
            }
          }}
          width="100%"
          height="100%"
        />
      </div>
    )
  }
}

export default VideoPlayer
