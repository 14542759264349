import socket from "../../services/socket";
import { getZamboniImageFromTeamIndex, getTeamNameFromIndex } from "../../helpers/zamboni";
import imgs from '../../helpers/imgs'
import './stylesheets/styleguide.scss'
import './stylesheets/Intro.scss'
import './stylesheets/Countdown.scss'

function Intro(props) {

  const requestTeamAssignment = () => {
    if (hasTeamAssignment()) return;
    socket.emit('message', JSON.stringify({
      type: 'assign'
    }))
  }

  const hasTeamAssignment = () => props.config.gameData && Number.isInteger(props.config.gameData.assignedTeamIndex);

  if (!hasTeamAssignment()){
    return(
      <div className="intro">
        <div className="intro-container">
          <div className="main-content">
            <div className="zams">
              <img className="animzam slides slides-hidden" id="zam1" src={imgs[8]} alt="zamboni pixel art"></img>
              <img className="animzam slides slides-hidden" id="zam2" src={imgs[10]} alt="zamboni pixel art"></img>
              <img className="animzam slides slides-hidden" id="zam3" src={imgs[9]} alt="zamboni pixel art"></img>
            </div>
            <hr></hr>
            <h1> St. Louis Blues</h1>
            <h2> Zamboni Races Will Begin Soon… </h2>
            <hr></hr>
            <button onClick={requestTeamAssignment}>Tap To Join A Team </button>
            <div className="sponsor">
              <p>Sponsored By:</p>
              <img className="dobbs" src={imgs[3]} alt="Dobbs logo" />
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return(
      <div className="intro countdown-to-start">
        <div className="countdown-to-start">
          <div className='countdown-content'>
            <div className="top-content">
              <hr></hr>
              <h2>Congrats, you're on...</h2>
              <hr></hr>
            </div>
            <div className='center-content'>
              <img className='zam' src={getZamboniImageFromTeamIndex(props.config.gameData.assignedTeamIndex)} alt="red zamboni pixel art" />
              <h1> {getTeamNameFromIndex(props.config.gameData.assignedTeamIndex)} </h1>
              <hr></hr>
              <p> waiting for countdown to begin...</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

}

export default Intro;
