import React, { useState } from 'react';
import socket from "../../services/socket";
import './stylesheets/styleguide.scss'
import './stylesheets/Intro.scss'

function Intro(props) {
  const [name, setName] = useState( '' );

  // const toInputUppercase = e => {
  //   e.target.value = ("" + e.target.value).toUpperCase();
  // };

  const setTriviaName = () => {
    if (hasTriviaName()) return;
    socket.emit('message', JSON.stringify({
      type: 'setTriviaName',
      data: name
    }))
  }

  const hasTriviaName = () => props.config.gameData && props.config.gameData.triviaRecord && props.config.gameData.triviaRecord.name;

  return(
    <div className='intro main-content'>
      { !hasTriviaName() && <h1 className="txt--gold-rich title">Let’s Play!</h1> }
      { !hasTriviaName() && <p className="introduction">How well do you know the St. Louis Blues?</p> }
      { hasTriviaName() &&
        <div className="wait">
          <div className="labels">
            <h5>Please Wait...</h5>
          </div>
          <div className="progress">
            <div className="bar"></div>
          </div>
        </div>
      }
      <div className="body">
        { hasTriviaName() && <p className="introduction">Hang tight! We’re waiting for other fans to join.</p> }
        { !hasTriviaName() && <p className="instructions">Get ready to compete to show your true colors.  But take your time&mdash;wrong answers will get you eliminated!</p> }
        { hasTriviaName() && <p className="instructions">The game will begin in just a few moments.</p> }
        { !hasTriviaName() &&
        <div className="name-form">
          <input
            // maxLength={3}
            onChange={e => setName(e.target.value)}
            // onInput={toInputUppercase}
            placeholder="Enter username"
            className="input"
          />
          <h5 className="txt--gold-rich cta" onClick={setTriviaName}>Submit</h5>
        </div>
        }
      </div>
    </div>
  )
}

export default Intro;
