import { useEffect, useState } from 'react'
import socket from "../../services/socket";
import imgs from '../../helpers/imgs'
import Nav from './Nav'
import Footer from './Footer'
import Intro from './Intro'
import CountdownToStart from './CountdownToStart'
import Competition from './Competition'
import PostGame from './PostGame'

function ZamboniRacing(props) {
  const [taps, setTaps] = useState(0);

  useEffect(() => {
    if (props.gameState !== "COMPETITION" && props.gameState !== "POST_GAME"  && taps) setTaps(0)
  }, [props.gameState, taps]);

  const tap = (e) => {
    socket.emit('message', JSON.stringify({
      type: 'tap'
    }));
    setTaps(taps + 1);
  }

  let component = 'Intro';
  if (props.gameState === "COUNTDOWN_TO_START") component = 'CountdownToStart';
  if (props.gameState === "COMPETITION") component = 'Competition';
  if (props.gameState === "POST_GAME") component = 'PostGame';

  // preload images
  useEffect(() => {
    imgs.forEach(image => {
      const img = new Image()
      img.src = image.fileName
    })
  }, [])

  return(
    <div className="zamboni-racing">
      <Nav/>
      {(component === 'Intro')
        ? <Intro config={props}/>
        : (component === 'CountdownToStart')
        ? <CountdownToStart config={props}/>
        : (component === 'Competition')
        ? <Competition config={props} tap={tap} taps={taps}/>
        : (component === 'PostGame')
        ?  <PostGame config={props} taps={taps}/>
        : null
      }
      <Footer/>
    </div>
  )
}

export default ZamboniRacing;
