import React from 'react';
import '@google/model-viewer/dist/model-viewer.js';
import 'focus-visible';

const ModelViewer = (props) => {
  const url = props.config.url ? props.config.url : '';
  const iosUrl = props.config.url ? props.config.iosUrl : '';

  return (
    <model-viewer
      loading="eager"
      id="AR-model"
      camera-controls
      src={url}
      alt="whtvr.app AR Component."
      ios-src={iosUrl}
      shadow-intensity="1"
      ar
      ar-scale="auto"
      magic-leap
    ></model-viewer>
  );
};
export default ModelViewer;
