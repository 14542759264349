import { getOrdinalTextFromPlace, getTeamNameFromIndex, getZamboniImageFromTeamIndex, getWinningImageFromTeamIndex} from '../../helpers/zamboni'
import "./stylesheets/ZamboniTeamMemberPlace.scss";

const ZamboniTeamMemberPlace = ({teamIndex, place}) => {

    return (
        <div className='zamboni-team-member-place'>
            { place === 0
              ? <img className="winning" src={getWinningImageFromTeamIndex(teamIndex)} alt="zamboni" />
              : <div className="losing">
                  <div className="ordinal-text">{getOrdinalTextFromPlace(place)}</div>
                  <img className="team-zamboni" src={getZamboniImageFromTeamIndex(teamIndex)} alt="zamboni"/>
                  {/* <div className="team-name">{getTeamNameFromIndex(teamIndex)}</div> */}
                </div>
            }
        </div>
    )
}

export default ZamboniTeamMemberPlace
