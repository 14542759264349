const gameShouldEndBasedOnScore = require("./rules").gameShouldEndBasedOnScore;
const getScoresProgressFloatFromScores =
  require("./rules").getScoresProgressFloatFromScores;
const ZAMBONI_START_STATE = "INTRO";

/**
 * For these transitions, we should return the new state and an action to take.
 */

const TRANSITIONS = {
  [ZAMBONI_START_STATE]: {
    // Begins the countdown to start. Clears out points.
    START_GAME: function () {
      const nextState = "COUNTDOWN_TO_START";

      const action = () => {
        // set countdown timer
        this.countdownTimer = this.countdownTimeInSeconds;

        // set game time limit
        this.gameTimer = this.gameTimeLimitInSeconds;

        // clear out scores
        emptyScores.bind(this)();

        // initialize countdown interval
        this.countdownInterval = setInterval(() => {
          const [nextState, action] =
            TRANSITIONS.COUNTDOWN_TO_START.COUNT.bind(this)();
          action.bind(this)();
          this.setGameState(nextState);
        }, 1000);

        // notify client of countdown timer
        this.notifyClients({
          countdownTimer: this.countdownTimer,
        });
      };
      return [nextState, action];
    },
  },
  COUNTDOWN_TO_START: {
    // Cancels the countdown and restores intro state. Also unassigns teams
    CANCEL_GAME: function () {
      const nextState = ZAMBONI_START_STATE;
      const action = () => {
        // cancel countdown interval function
        clearInterval(this.countdownInterval);

        // set countdown to zero
        this.countdownTimer = 0;

        // unassign teams
        clearTeams.bind(this)();
      };
      return [nextState, action];
    },
    // Decrements the counter?
    COUNT: function () {
      if (this.countdownTimer > 0) {
        const nextState = "COUNTDOWN_TO_START";
        const action = () => {
          this.countdownTimer--;
          // console.log(`[Zamboni] Countdown Timer is at: ${this.countdownTimer}`);

          //  notify client of countdownTimer
          this.notifyClients({
            countdownTimer: this.countdownTimer,
          });
        };

        return [nextState, action];
      } else {
        return TRANSITIONS.COUNTDOWN_TO_START.BEGIN_GAME.bind(this)();
      }
    },
    // When the count is 0 this should be triggered.
    BEGIN_GAME: function () {
      const nextState = "COMPETITION";
      const action = () => {
        // cancel countdown interval
        clearInterval(this.countdownInterval);

        // make sure scores are zero
        emptyScores.bind(this)();

        // initalize game countdown interval
        this.gameInterval = setInterval(() => {
          const [nextState, action] =
            TRANSITIONS.COMPETITION.COMPETE.bind(this)();
          action.bind(this)();
          this.setGameState(nextState);
        }, 1000);

        // notify jumbotron of gameTimer
        this.notifyJumbotron({
          gameTimer: this.gameTimer,
        });

        // notify client of countdownTimer and gameTimer
        this.notifyClients({
          countdownTimer: this.countdownTimer,
          gameTimer: this.gameTimer,
        });
      };

      return [nextState, action];
    },
  },
  COMPETITION: {
    // This could keep the game going and decrement the countdown.
    COMPETE: function () {
      if (gameShouldEnd(this.scores, this.gameTimer)) {
        return TRANSITIONS.COMPETITION.COMPLETE_GAME.bind(this)();
      } else {
        const nextState = "COMPETITION";
        const action = () => {
          this.gameTimer--;

          // notify jumbotron of gameTimer change and current scores (via scoresProgressFloat)
          this.notifyJumbotron({
            gameTimer: this.gameTimer,
            scoresProgressFloat: getScoresProgressFloatFromScores(this.scores),
          });

          // notify clients of gameTimer
          this.notifyClients({
            gameTimer: this.gameTimer,
          });

          // console.log(`[Zamboni] Game Timer is at: ${this.gameTimer}`);
        };
        return [nextState, action];
      }
    },
    // This transition should occur when a completion condition is met.
    COMPLETE_GAME: function () {
      const nextState = "POST_GAME";
      const action = () => {
        // clear game countdown interval
        clearInterval(this.gameInterval);

        // calculate teamPlaces
        const teamPlaces = calculatePlacesFromScores(this.scores);
        //  notify client of teamPlaces
        this.notifyClients({
          teamPlaces,
        });

        this.notifyJumbotron({
          teamPlaces,
        });
      };

      return [nextState, action];
    },
    // This is when the host kills the game and takes us back to intro.
    CANCEL_GAME: function () {
      const nextState = ZAMBONI_START_STATE;
      const action = () => {
        // clear game countdown interval
        clearInterval(this.gameInterval);

        // clear teams
        clearTeams.bind(this)();
      };
      return [nextState, action];
    },
  },
  POST_GAME: {
    // Takes us back to the Countdown to start without resetting teams
    REMATCH: function () {
      return TRANSITIONS.INTRO.START_GAME.bind(this)();
    },
    // Takes us back to the intro
    RESTART: function () {
      const nextState = ZAMBONI_START_STATE;
      const action = () => {
        // clear out teams
        clearTeams.bind(this)();
      };

      return [nextState, action];
    },
  },
};

function emptyScores() {
  this.scores = [0, 0, 0];
}

function clearTeams() {
  this.teams.map((team) => {
    return team.map((socket) => {
      delete socket.teamIndex;
      return true;
    });
  });
  this.teams = [[], [], []];

  // notify all users of no teams
  this.sendToAll(undefined, {
    type: "unassign_from_team",
  });
}

// should based on the Kane algorithm that was promised
const gameShouldEnd = (scores, timeRemaining) => {
  if (timeRemaining <= 0) {
    return true;
  } else {
    return gameShouldEndBasedOnScore(scores);
  }
};

const calculatePlacesFromScores = (scores) => {
  // add team index to scores
  const teamsWithScoresAsAnArrayOfObjects = scores.map((score, teamIndex) => ({
    teamIndex: `${teamIndex}`,
    score,
  }));

  // sort by score
  teamsWithScoresAsAnArrayOfObjects.sort((a, b) => b.score - a.score);

  return teamsWithScoresAsAnArrayOfObjects.map(
    (teamIndexWithScoreObject) => teamIndexWithScoreObject.teamIndex
  );
};

const HOST_ACTIONS_BY_STATE = {
  [ZAMBONI_START_STATE]: {
    forward: "START_GAME",
  },
  COUNTDOWN_TO_START: {
    forward: "BEGIN_GAME",
    back: "CANCEL_GAME",
  },
  COMPETITION: {
    forward: "COMPLETE_GAME",
    back: "CANCEL_GAME",
  },
  POST_GAME: {
    forward: "RESTART",
    back: "REMATCH",
  },
};

export {
  ZAMBONI_START_STATE,
  TRANSITIONS,
  HOST_ACTIONS_BY_STATE,
};
