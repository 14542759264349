// import socket from "../../services/socket";
import { gsap } from "gsap";
import React from 'react'
import zamOneBlue from '../../assets/Zam Blue@3x.png'
import zamThreeRed from '../../assets/Zam Red@3x.png'
import zamTwoTan from '../../assets/Zam Tan@3x.png'

class Competition extends React.Component {
  // constructor(props) {
  //   super(props);
  // }

  componentDidMount() {
    console.log("componentDidMount competition")
    this.tl = gsap.timeline();
    this.tl.to(".jumbotron-view", {
      "background-size": "100%",
      "background-position": "top 0% center",
      duration: 1.5,
      onComplete: () => {
        const jumbotronText = document.querySelectorAll(".jumbotron-h")
        if (jumbotronText) jumbotronText.forEach(el => { el.style.color = "white" });
      }
    }).to(".zambo", {
      left: "0%",
      ease: "power1.out",
      duration: 3
    });
  }

  componentWillUnmount() {
    this.tl.clear();
    const jumbotronText = document.querySelectorAll(".jumbotron-h")
    if (jumbotronText) jumbotronText.forEach(el => { el.style.color = "transparent" });
    const zambos = document.querySelectorAll(".zambo");
    if (zambos) zambos.forEach(el => el.style.left = "-50%");
  }

  componentDidUpdate() {
    console.log(this.props);

    if (this.props.config.gameData.scoresProgressFloat) {  // this will be null until COMPETITION phase

      // 60% is the max we want the left or zambo goes through the wall, so multiply 0-1 val
      // first by 100 to get a percentage, then normalize to .65 "max left"
      // console.log(
      //   this.props.config.gameData.scoresProgressFloat[0] * 100 * .65,
      //   this.props.config.gameData.scoresProgressFloat[1] * 100 * .65,
      //   this.props.config.gameData.scoresProgressFloat[2] * 100 * .65)

      gsap.to(".team-one", {
        left: (this.props.config.gameData.scoresProgressFloat[0] * 100 * .65) + `%`,
        ease: "power1.in",
        duration: .25
      });
      gsap.to(".team-two", {
        left: (this.props.config.gameData.scoresProgressFloat[1] * 100 * .65) + `%`,
        ease: "power1.in",
        duration: .25
      });
      gsap.to(".team-three", {
        left: (this.props.config.gameData.scoresProgressFloat[2] * 100 * .65) + `%`,
        ease: "power1.in",
        duration: .25
      });
    }
  };

  render () {
    return(
      <div className="competition">
        <div className="jumbotron-text">
          <h2 className="timer-label jumbotron-h">
            {this.props.config.gameState === "COUNTDOWN_TO_START" ? "Til Start" : `Time Left`}
          </h2>
          <h1 className="timer jumbotron-h">
            {this.props.config.gameState === "COMPETITION" ?
              this.props.config.gameData.gameTimer : this.props.config.gameData.countdownTimer}
          </h1>
        </div>

        <div className="team team-one">
          <img className="zambo team-one-zambo" src={zamOneBlue} alt="team zamboni"/>
        </div>
        <div className="team team-two">
          <img className="zambo team-two-zambo" src={zamTwoTan} alt="team zamboni"/>
        </div>
        <div className="team team-three">
          <img className="zambo team-three-zambo" src={zamThreeRed} alt="team zamboni"/>
        </div>
      </div>
    )
  }
}

export default Competition;
