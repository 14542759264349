import React from 'react'
require('dotenv').config()

class VideoPlayer extends React.Component {

  render() {
    return (
      <div className="ImageRender">
        <img
          alt="Meaningful Alt Text"
          src={this.props.config && this.props.config.url ? this.props.config.url : ''}
          width="100%"
          height="auto"
        />
      </div>
    )
  }
}

export default VideoPlayer
