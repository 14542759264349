import zamboni_blue from '../assets/imgs/zam-blue.png'
import zamboni_red from '../assets/imgs/zam-red.png'
import zamboni_tan from '../assets/imgs/zam-tan.png'
import team1 from '../assets/imgs/win-team1.png'
import team2 from '../assets/imgs/win-team2.png'
import team3 from '../assets/imgs/win-team3.png'


/**
 *
 * @param {number} teamIndex the zero indexed team index
 * @returns a team name based on the index adjusted to start at 1
 */
export function getTeamNameFromIndex(teamIndex) {
    if (!Number.isInteger(teamIndex) || teamIndex > 2 || teamIndex < 0) {
        return `Team ?`;
    }

    return `Team ${teamIndex+1}`;
}

/**
 * Turns a zero indexed place into ordinal text
 *
 * @param {number} place zero indexed place
 * @returns Text for the place (e.g. 0 becomes "1st Place")
 */
export function getOrdinalTextFromPlace(place) {
    switch (place) {
        case 0:
            return "1st Place";
        case 1:
            return "2nd Place";
        case 2:
            return "3rd Place";
        default:
            return `${place+1}th Place`
    }
}

export function getZamboniImageFromTeamIndex(teamIndex) {
    switch (teamIndex) {
        case 0:
            return zamboni_blue;
        case 1:
            return zamboni_tan;
        case 2:
            return zamboni_red;
        default:
            throw new Error('Team index must be between 0 and 2, inclusive');
    }
}

export function getWinningImageFromTeamIndex(teamIndex) {
    switch (teamIndex) {
        case 0:
            return team1;
        case 1:
            return team2;
        case 2:
            return team3;
        default:
            throw new Error('Team index must be between 0 and 2, inclusive');
    }
}
