require('dotenv').config()

let serverURL = 'https://whtvr.app'

if (process.env.REACT_APP_SERVER_URL) serverURL = process.env.REACT_APP_SERVER_URL
else if (process.env.REACT_APP_NODE_ENV === 'development') {
  const PORT = process.env.REACT_APP_SERVER_PORT || 3003
  serverURL = `http://localhost:${PORT}`
}

module.exports = { ServerURL: serverURL}
