import './stylesheets/PostGame.scss';
import dobbs from '../../assets/imgs/logo-dobbs.png';
import ZamboniPlace from './ZamboniPlace'
import ZamboniTeamMemberPlace from './ZamboniTeamMemberPlace'
import { useEffect, useState } from 'react';
import imgs from '../../helpers/imgs'
import { gsap } from "gsap";


function PostGame({config, taps}) {
  const [places, setPlaces] = useState();
  const [place, setPlace] = useState();

  function openCoupon(){
    gsap.to(".redeem", {duration: 0.5, x: 0, opacity: 1, scale: 1});
  }

  function closeCoupon(){
    gsap.to(".redeem", {duration: 0.5, x: 350, opacity: 0, scale: 0});
  }

  useEffect(()=>{
    if (config.gameData && config.gameData.teamPlaces && Array.isArray(config.gameData.teamPlaces)) {
      setPlaces(config.gameData.teamPlaces);

      if (Number.isInteger(config.gameData.assignedTeamIndex)) {
        const place = config.gameData.teamPlaces.indexOf(config.gameData.assignedTeamIndex)
        setPlace(place);
      }
    } else {
      setPlaces(undefined);
    }
  }, [config])

  return(
    <div>
      <div className="post-game">
        <div className="zamboni-places">
          {places
            ? Number.isInteger(config.gameData.assignedTeamIndex)
                ? (<div>
                  <ZamboniTeamMemberPlace teamIndex={config.gameData.assignedTeamIndex} place={place}/>
                  <p className="stats">Team { config.gameData.assignedTeamIndex + 1 } <span className="pipe">|</span> Total Taps: {taps}</p>
                </div>)
                : places.map((teamIndex, place) => <ZamboniPlace key={place} teamIndex={teamIndex} place={place} />)
            : <div className="score-calculation">calculating scores...</div>
          }
        </div>
        <div className='offerRedeem'>
          <div className='offer'>50% off your next oil change from <img src={dobbs} alt="Dobbs Tire &amp; Auto Centers"/></div>
          <button onClick={openCoupon}> Redeem </button>
        </div>
      </div>

      <div className='redeem'>

      <div className='coupon-container'>
        <h1> 50% OFF</h1>
        <h2> Your next oil change from:</h2>
        <img className="dobbs" src={imgs[3]} alt="Dobbs logo" />
        <button onClick={closeCoupon}> Save Coupon</button>
        <p className="disclaimer">RAIN CHECKS AVAILABLE. NO CARRYOUTS. NOT COMBINED WITH ANY OTHER OFFER.
        CERTAIN RESTRICTIONS APPLY. SEE STORE FOR DETAILS. OFFER EXPIRES 3/31/22.</p>
        </div>
      </div>

    </div>
  )
}

export default PostGame;
