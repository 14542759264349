import axios from "axios";
import to from "await-to-js";
import React from "react";
import socket from "../services/socket";
import Button from "@material-ui/core/Button";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Box from "@material-ui/core/Box";
// import IconButton from '@material-ui/core/IconButton'
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import SettingsIcon from "@material-ui/icons/Settings";
// import { withStyles } from '@material-ui/core/styles'
import MessageInput from "./MessageInput";
import SettingsDialog from "./SettingsDialog";
import StreamBroadcaster from "./StreamBroadcaster";
// import MediaServerStreamBroadcaster from './MediaServerStreamBroadcaster'
// import StreamAppWebRTCPublishPort from './StreamAppWebRTCPublishPort'
import { ServerURL } from "../data/ServerURL";
// import RotateIcon from '../assets/rotate.svg';
import RotateIcon from "@material-ui/icons/RotateRight";
// Relative imports outside of src/ are not supported, added symlink
// const ZamboniHostActionsByState = require("../../server/games/Zamboni").HOST_ACTIONS_BY_STATE;
const ZamboniHostActionsByState = require("../games/Zamboni").HOST_ACTIONS_BY_STATE;
const TriviaHostActionsByState = require("../games/Trivia").HOST_ACTIONS_BY_STATE;

class HostView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      settingsDialogOpen: false,
      renderStreamBroadcaster: false,
      // BEGIN TEMP
      renderMediaServerStreamBroadcaster: false,
      activeAccordion: null,
      // renderMediaServerStreamBroadcaster: true,
      // activeAccordion: 'mediaServerBroadcast',
      // END TEMP
      actions: [],
      models: [],
      videos: [],
      images: [],
      animations: [],
      saving: false,
      broadcastRotation: 0,
      autoplay: false,
      transitionName: "",
    };
  }

  sendComponent = ({ component = "", config = {} }) => {
    socket.emit(
      "message",
      JSON.stringify({
        type: "component",
        target: "all",
        component: {
          type: component,
          config,
        },
      })
    );
  };

  sendTransition = (transition_name) => {
    socket.emit(
      "message",
      JSON.stringify({
        type: "iterateGameState",
        gameState: {
          transition: transition_name,
        },
      })
    );
  };

  emitAnimation = (action, index) => {
    const data = JSON.stringify({
      type: "animation",
      target: "all",
      data: {
        animation: {
          id: action.id,
          name: action.label,
          phase: action.config.phase,
        },
      },
    });

    socket.emit("message", data);
  };

  sendAnimation = async (action, index) => {
    this.setState({ saving: true });

    const [err] = await to(
      axios.post(`${ServerURL}/api/animation`, {
        id: action.id,
        config: action.config,
      })
    );

    if (err) console.warn(err);

    this.getActions();
    this.setState({ saving: false });
    this.emitAnimation(action, index);
  };

  prepareBroadcast = () => {
    this.setState({ renderStreamBroadcaster: true });
  };

  prepareMediaServerBroadcast = () => {
    this.setState({ renderMediaServerStreamBroadcaster: true });
  };

  handleOpenSettingsDialog = () => {
    this.setState({ settingsDialogOpen: true });
  };

  handleCloseSettingsDialog = () => {
    this.setState({ settingsDialogOpen: false });
  };

  handleAccordionChange = (panel) => (event, newExpanded) => {
    this.setState({ activeAccordion: newExpanded ? panel : false });
  };

  handleTransitionNameChange = (event) => {
    this.setState({ transitionName: event.target.value });
  };

  getActions = async () => {
    const [err, res] = await to(axios.get(`${ServerURL}/api/action`));

    if (err) {
      console.warn(err);
      return;
    }

    const _actions = res?.data || [];

    this.setState({ actions: _actions });

    // reset
    this.setState({ models: [] });
    this.setState({ videos: [] });
    this.setState({ images: [] });
    this.setState({ animations: [] });

    const _models = [];
    const _videos = [];
    const _images = [];
    const _animations = [];

    _actions.forEach((action) => {
      if (action.type === "model") {
        action.component = "ARViewer";
        _models.push(action);
      }

      if (action.type === "video") {
        action.component = "VideoPlayer";
        _videos.push(action);
      }

      if (action.type === "image") {
        action.component = "ImageRender";
        _images.push(action);
      }

      if (action.type === "animation") {
        _animations.push(action);
      }
    });

    this.setState({ models: _models });
    this.setState({ videos: _videos });
    this.setState({ images: _images });
    this.setState({ animations: _animations });
  };

  setActions = (_actions) => {
    this.setState({ actions: _actions });
  };

  // run once
  componentDidMount() {
    this.getActions();
  }

  render() {
    return (
      <div className="HostView">
        <div
          className="settings-button"
          onClick={this.handleOpenSettingsDialog}
        >
          <SettingsIcon></SettingsIcon>
        </div>

        <SettingsDialog
          open={this.state.settingsDialogOpen}
          onClose={this.handleCloseSettingsDialog}
          getActions={this.getActions}
          setActions={this.setActions}
          meta={this.props.meta}
          setMeta={this.props.setMeta}
          actions={this.state.actions}
          models={this.state.models}
          videos={this.state.videos}
          images={this.state.images}
          animations={this.state.animations}
          setSettingsDialogOpen={this.handleOpenSettingsDialog}
        />

        <Box mt={10}>
          <h3>Host Actions</h3>
        </Box>

        {/* TODO: abstract out optional accordion slotted component */}
        <Accordion
          square
          expanded={this.state.activeAccordion === "message"}
          onChange={this.handleAccordionChange("message")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="message-content"
            id="message-header"
          >
            <Typography>Message</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <MessageInput />
          </AccordionDetails>
        </Accordion>

        {/* <Accordion square
          expanded={this.state.activeAccordion === 'mediaServerBroadcast'}
          onChange={this.handleAccordionChange('mediaServerBroadcast')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="mediaServerBroadcast-content"
            id="mediaServerBroadcast-header"
          >
            <Typography>Media Server Broadcast</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box width={1} display="flex" flexDirection="row" flexWrap="wrap">
              <Box mb={2} mr={2}><Button variant="contained" onClick={this.prepareMediaServerBroadcast}>Prepare Broadcast</Button></Box>
              {this.state.renderMediaServerStreamBroadcaster ?
                <Box className="mediaServerBroadcast-actions" width={1}>
                  <MediaServerStreamBroadcaster config={this.props.component.config} />
                </Box>
                :
                ''
              }
              <Box mb={2} mr={2}>
                <Button variant="contained" color="primary" onClick={() => {
                  this.sendComponent({})
                  this.sendComponent({ component: 'MediaServerStreamWatcher', config: {} })
                }
                }>Render Broadcast</Button>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion> */}

        <Accordion
          square
          expanded={this.state.activeAccordion === "broadcast"}
          onChange={this.handleAccordionChange("broadcast")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="broadcast-content"
            id="broadcast-header"
          >
            <Typography>Native WebRTC Broadcast</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box width={1} display="flex" flexDirection="row" flexWrap="wrap">
              <Box mb={2} mr={2}>
                <Button variant="contained" onClick={this.prepareBroadcast}>
                  Prepare Broadcast
                </Button>
              </Box>
              {/* TODO: abstract into dialog component */}
              {this.state.renderStreamBroadcaster ? (
                <Box className="broadcast-actions" width={1}>
                  <StreamBroadcaster />
                </Box>
              ) : (
                ""
              )}
              <Box mb={2} mr={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    this.sendComponent({});
                    this.sendComponent({
                      component: "StreamWatcher",
                      config: { lights: false },
                    });
                  }}
                >
                  Render Broadcast
                </Button>
              </Box>
              <Box mr={2} mb={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<RotateIcon />}
                  onClick={() => {
                    var rotation = this.state.broadcastRotation;
                    if (rotation === 360) rotation = 0;
                    rotation += 90;
                    this.setState({ broadcastRotation: rotation });
                    this.sendComponent({});
                    this.sendComponent({
                      component: "StreamWatcher",
                      config: { lights: false, rotate: rotation },
                    });
                  }}
                >
                  Rotate Orientation
                </Button>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion
          square
          expanded={this.state.activeAccordion === "models"}
          onChange={this.handleAccordionChange("models")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="models-content"
            id="models-header"
          >
            <Typography>Models</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              {this.state.models.map((action, index) => (
                <Box mb={2} key={index}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.sendComponent(action)}
                  >
                    {action.label}
                  </Button>
                </Box>
              ))}
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion
          square
          expanded={this.state.activeAccordion === "images"}
          onChange={this.handleAccordionChange("images")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="images-content"
            id="images-header"
          >
            <Typography>Images</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              {this.state.images.map((action, index) => (
                <Box mb={2} key={index}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.sendComponent(action)}
                  >
                    {action.label}
                  </Button>
                </Box>
              ))}
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion
          square
          expanded={this.state.activeAccordion === "videos"}
          onChange={this.handleAccordionChange("videos")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="videos-content"
            id="videos-header"
          >
            <Typography>Videos</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box display="flex" flexDirection="row" flexWrap="wrap">
              {/* TODO: toggle action active property  */}
              <FormControlLabel
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                label="Autoplay?"
                control={
                  <Switch
                    checked={this.state.autoplay}
                    onChange={() => {
                      this.setState({ autoplay: !this.state.autoplay });
                    }}
                    color="primary"
                  />
                }
              />
              {this.state.videos.map((action, index) => {
                if (action.config) {
                  action.config.autoplay = this.state.autoplay;
                } else {
                  action.config = {
                    autoplay: this.state.autoplay,
                  };
                }
                return (
                  <Box mr={2} mb={2} key={index}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => this.sendComponent(action)}
                    >
                      {action.label}
                    </Button>
                  </Box>
                );
              })}
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion
          square
          expanded={this.state.activeAccordion === "animations"}
          onChange={this.handleAccordionChange("animations")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="animations-content"
            id="animations-header"
          >
            <Typography>Animations</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              {this.state.animations.map((action, index) => {
                return (
                  <div key={index}>
                    <Box mb={2}>
                      <Typography>
                        {action.label} | {action.config?.phase || 0} /{" "}
                        {action.config?.numberOfPhases}
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="row"
                      flexWrap="wrap"
                      key={index}
                    >
                      <Box mb={2} mr={2}>
                        <Button
                          disabled={this.state.saving}
                          variant="contained"
                          onClick={() => {
                            if (action.config.phase > 0) {
                              action.config.phase--;
                            } else {
                              action.config.phase = Number(
                                action.config.numberOfPhases
                              );
                            }

                            this.sendAnimation(action, index);
                          }}
                        >
                          Previous Phase
                        </Button>
                      </Box>
                      <Box mb={2} mr={2}>
                        <Button
                          disabled={this.state.saving}
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            if (!action.config.phase) {
                              action.config.phase = 0;
                            }

                            if (
                              action.config.phase < action.config.numberOfPhases
                            ) {
                              action.config.phase++;
                            } else {
                              action.config.phase = 0;
                            }

                            this.sendAnimation(action, index);
                          }}
                        >
                          Next Phase
                        </Button>
                      </Box>
                    </Box>
                  </div>
                );
              })}
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion
          square
          expanded={this.state.activeAccordion === "zamboni-racing"}
          onChange={this.handleAccordionChange("zamboni-racing")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="zamboni-racing-content"
            id="zamboni-racing-header"
          >
            <Typography>Zamboni Racing</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box width={1} display="flex" flexDirection="row" flexWrap="wrap">
              <Box mb={2} mr={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    this.sendComponent({ component: "ZamboniRacing"});
                  }}
                >
                  Render Component
                </Button>
              </Box>
              <Box mb={2} mr={2}>
                <Typography>Current State: {this.props.gameState.replace(/_/g, " ")}</Typography>
              </Box>
              {Object.entries(ZamboniHostActionsByState).map(([gameState, { forward, back }]) => {
                let disabled = true;
                if (gameState === this.props.gameState) disabled = false;
                return (
                  <Box key={gameState} width={1} display="flex" flexDirection="row" flexWrap="wrap">
                    {forward && <Box mb={2} mr={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={disabled}
                        onClick={() => {
                          this.sendTransition(forward);
                        }}
                      >
                        {forward.replace(/_/g, " ")}
                      </Button>
                    </Box>}
                    {back && <Box mb={2} mr={2}>
                      <Button
                        variant="contained"
                        color="secondary"
                        disabled={disabled}
                        onClick={() => {
                          this.sendTransition(back);
                        }}
                      >
                        {back.replace(/_/g, " ")}
                      </Button>
                    </Box>}
                  </Box>
                )
              })}
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion
          square
          expanded={this.state.activeAccordion === "trivia"}
          onChange={this.handleAccordionChange("trivia")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="trivia-content"
            id="trivia-header"
          >
            <Typography>Trivia</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box width={1} display="flex" flexDirection="row" flexWrap="wrap">
              <Box mb={2} mr={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    this.sendComponent({ component: "Trivia"});
                  }}
                >
                  Render Component
                </Button>
              </Box>
              <Box mb={2} mr={2}>
                <Typography>Current State: {this.props.gameState.replace(/_/g, " ")}</Typography>
              </Box>
              {Object.entries(TriviaHostActionsByState).map(([gameState, { forward, back }]) => {
                let disabled = true;
                if (gameState === this.props.gameState) disabled = false;
                return (
                  <Box key={gameState} width={1} display="flex" flexDirection="row" flexWrap="wrap">
                    {forward && <Box mb={2} mr={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={disabled}
                        onClick={() => {
                          this.sendTransition(forward);
                        }}
                      >
                        {forward.replace(/_/g, " ")}
                      </Button>
                    </Box>}
                    {back && <Box mb={2} mr={2}>
                      <Button
                        variant="contained"
                        color="secondary"
                        disabled={disabled}
                        onClick={() => {
                          this.sendTransition(back);
                        }}
                      >
                        {back.replace(/_/g, " ")}
                      </Button>
                    </Box>}
                  </Box>
                )
              })}
            </Box>
          </AccordionDetails>
        </Accordion>

        <Box my={2}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => this.sendComponent({})}
          >
            Reset
          </Button>
        </Box>
      </div>
    );
  }
}

export default HostView;
