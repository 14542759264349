// import React, { useState, useEffect } from 'react';
// import socket from "../../services/socket";
import { getZamboniImageFromTeamIndex, getTeamNameFromIndex } from "../../helpers/zamboni";
// import PaperTrack from '../../assets/sounds/paper.mp3';
import imgs from '../../helpers/imgs'


function Competition(props) {
  return(
    <div className="competition" >
      <div className="stats">
        <div className="stat">
          <h4> {getTeamNameFromIndex(props.config.gameData.assignedTeamIndex)} </h4>
          <img className='zamboni' src={getZamboniImageFromTeamIndex(props.config.gameData.assignedTeamIndex || 0)} alt="zamboni"/>
        </div>
        <div className="stat">
          <h4>Time</h4>
          <h3>{props.config.gameData.gameTimer ? props.config.gameData.gameTimer : 30}</h3>
        </div>
        <div className="stat">
          <h4>Taps</h4>
          <h3>{props.taps}</h3>
        </div>
      </div>
      <div className="tap" onClick={props.tap}>
        <img className="inner" src={imgs[4]} alt='tap icon'></img>
      </div>
      <div className="swipe-icon"><img src={imgs[1]} alt='tap icon'></img></div>
      <p className="instructions">
        <span>On the target above,
          <span className="heavy"> tap as fast as you can</span>
        </span>
        <br/>
        to help move your team’s zamboni.
      </p>
    </div>
  )
}

export default Competition;
