import React from 'react'
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import socket from "../services/socket";

class ConnectionStatusSnackbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      severity: 'info',
      message: 'Waiting to connect',
      open: false,
      autoHideDuration: 2000
    }

    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    socket.on('disconnect', data => {
      this.openSnackbar('warning', 'Disconnected')
    })

    socket.on('connect', data => {
      this.openSnackbar('success', 'Connected')
    })

    socket.on('connect_error', data => {
      console.error(data)
      this.openSnackbar('error', 'Connection Error', null)
    })
  }

  openSnackbar(severity, message, autoHideDuration = 3000) {
    this.setState({
      severity,
      message,
      autoHideDuration,
      open: true,
    })
  }

  handleClose() {
    this.setState({
      open: false
    })
  }

  render() {
    return (
      <Snackbar
       open={this.state.open}
       autoHideDuration={this.state.autoHideDuration}
       onClose={this.handleClose}
      >
        <Alert severity={this.state.severity}>
          {this.state.message}
        </Alert>
      </Snackbar>
    )
  }
}

export default ConnectionStatusSnackbar
