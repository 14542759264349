import Intro from './Intro'
import Competition from './Competition'
import PostGame from './PostGame'
import bluesLogoDark from '../../assets/imgs/logo-blues-dark.svg';

function Trivia(props) {
  let component = 'Intro';
  if (props.gameState === "COUNTDOWN_TO_START") component = 'CountdownToStart';
  if (props.gameState === "COMPETITION") component = 'Competition';
  if (props.gameState === "POST_GAME") component = 'PostGame';

  const minifyLogo = () => {
    return (props.gameData && props.gameData.triviaRecord && props.gameData.triviaRecord.name) || props.gameState !== 'INTRO'
  }

  return(
    <div className="trivia">
      <img className={`logo ${minifyLogo() ? 'minify' : ''}`} src={bluesLogoDark} alt="Blues Logo"/>
      {(component === 'Intro')
        ? <Intro config={props}/>
        : (component === 'Competition')
        ? <Competition config={props}/>
        : (component === 'PostGame')
        ?  <PostGame config={props}/>
        : null
      }
    </div>
  )
}

export default Trivia;
