const START_STATE = "INTRO";

const HOST_ACTIONS_BY_STATE = {
  [START_STATE]: {
    forward: "START_GAME",
  },
  COMPETITION: {
    forward: "COMPLETE_GAME",
    back: "CANCEL_GAME",
  },
  POST_GAME: {
    forward: "RESTART",
  },
};

export {
  START_STATE,
  HOST_ACTIONS_BY_STATE,
};
