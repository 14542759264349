import axios from 'axios'
import React, { useState, useEffect } from 'react';
import './App.scss';
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';
import WebSocketPlayground from './components/WebSocketPlayground';
const { ServerURL } = require('../src/data/ServerURL')
const to = require('await-to-js').default

const themeDark = createMuiTheme({
  palette: {
    type: 'dark'
  }
});

function App() {
  const [meta, setMeta] = useState(null)

  const getCookie = cname => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return ""
  }

  // run once
  useEffect(() => {

    if (!meta) {
      if (document.cookie) {
        const metaJSON = getCookie('meta')

        if (metaJSON) {
          try {
            const meta = JSON.parse(metaJSON)

            setMeta(meta)
          }
          catch (err) {
            console.warn(err)

            // TODO: set default meta in external file
            // default meta
            const meta = {
              id: 0,
              project: 'Paradowski',
              logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/1280px-React-icon.svg.png',
              active: true,
            }

            setMeta(meta)
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const checkMeta = async () => {
    if (!meta) {
      const [err, res] = await to(axios.get(`${ServerURL}/api/meta`))

      if (err) {
        console.warn(err)
        return
      }

      const _metas = res?.data || []
      if (_metas.length) {
        const _activeMeta = _metas.find(({ active }) => active)

        if (_activeMeta) {
          setMeta(_activeMeta)
        }
      }
    }
  }

  checkMeta()

  return (
    <MuiThemeProvider theme={themeDark}>
      <CssBaseline />
      <Container className="App" maxWidth="sm">
        {/* TODO: Implement React Router */}
        <WebSocketPlayground
          meta={meta}
          setMeta={setMeta}
        />
      </Container>
    </MuiThemeProvider>
  );
}

export default App;
