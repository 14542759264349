import startingSoon from '../../assets/starting_soon.png';

function Intro(props) {
  return(
    <div className="intro">
      <img src={startingSoon} alt="starting soon"/>
    </div>
  )
}

export default Intro;
