import {getOrdinalTextFromPlace, getTeamNameFromIndex, getZamboniImageFromTeamIndex} from '../../helpers/zamboni'
import "./stylesheets/ZamboniPlace.scss";


const ZamboniPlace = ({teamIndex, place}) => {

    return (
        <div className='zamboni-place'>
            <div className="ordinal-text">{getOrdinalTextFromPlace(place)}</div>
            <img className="team-zamboni" src={getZamboniImageFromTeamIndex(teamIndex)} alt="zamboni"/>
            <div className="team-name">{getTeamNameFromIndex(teamIndex)}</div>
        </div>
    )
}

export default ZamboniPlace
