import { useEffect } from 'react'
import imgs from '../../helpers/imgs'
import Intro from './Intro'
import Competition from './Competition'
import JumboPostGame from './JumboPostGame'
import bosTheme from '../../assets/bos.mp3'

function JumbotronView(props) {
  let component  = 'Intro';
  if (props.gameState === 'COUNTDOWN_TO_START' || props.gameState === 'COMPETITION') component = 'Competition';
  if (props.gameState === 'POST_GAME') component = 'PostGame';

  // preload images
  useEffect(() => {
    imgs.forEach(image => {
      const img = new Image()
      img.src = image.fileName
    })
  }, [])

  return(
    <div className="jumbotron-view">
      <audio src={bosTheme} autoPlay={false}></audio>
      {component === 'Intro' &&
        <Intro config={props}/>
      }
      {component === 'Competition' &&
        <div>
          <Competition config={props}/>
        </div>
      }
      {component === 'PostGame' &&
        <div>
          <JumboPostGame config={props} />
        </div>
      }
    </div>
  )
}

export default JumbotronView;
