import React from 'react'
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import socket from "../services/socket";

class MessageSnackbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      message: '',
      open: false,
      autoHideDuration: 6000
    }

    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    socket.on('message', data => {
      this.setState({
        message: data.message,
        open: true
      })
    })
  }

  handleClose() {
    this.setState({
      open: false
    })
  }

  TransitionDown(props) {
    return <Slide {...props} direction="down" />;
  }

  render() {
    return (
      <Snackbar
        open={this.state.open}
        autoHideDuration={this.state.autoHideDuration}
        onClose={this.handleClose}
        // bodyStyle={{ backgroundColor: 'white', color: 'black' }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={this.TransitionDown}
      >
        <SnackbarContent
          message={this.state.message}
          action={
            <IconButton aria-label="close" onClick={this.handleClose} color="primary">
              <CloseIcon/>
            </IconButton>
          }
        />
      </Snackbar>
    )
  }
}

export default MessageSnackbar
