import React from 'react'
// import socket from "../services/socket";
import StreamWatcher from './StreamWatcher';
import MediaServerStreamWatcher from './MediaServerStreamWatcher';
import VideoPlayer from './VideoPlayer';
import ModelViewer from './ModelViewer';
import ImageRender from './ImageRender';
import ZamboniRacing from './ZamboniRacing';
import Trivia from './Trivia';
class PeerView extends React.Component {
  render() {
    return (
      <div className="PeerView">
        {/* TODO: cleanup conditional syntax */}
        {!this.props.component.type && this.props.connected && this.props?.meta?.logo && <img className="connected" src={this.props.meta.logo} alt="Logo"/>}
        {this.props.component.type === 'StreamWatcher' && !this.props.renderStreamBroadcaster && <StreamWatcher config={this.props.component.config}/>}
        {this.props.component.type === 'MediaServerStreamWatcher' && !this.props.renderMediaServerStreamBroadcaster && <MediaServerStreamWatcher config={this.props.component.config}/>}
        {this.props.component.type === 'ARViewer' && <ModelViewer config={this.props.component.config}/>}
        {this.props.component.type === 'VideoPlayer' && <VideoPlayer config={this.props.component.config}/>}
        {this.props.component.type === 'ImageRender' && <ImageRender config={this.props.component.config} />}
        <div style={{ display: this.props.component.type === 'ZamboniRacing' ? null : 'none' }}>
          <ZamboniRacing
            config={this.props.component.config}
            gameState={this.props.gameState}
            gameData={this.props.gameData}
          />
        </div>
        <div style={{ display: this.props.component.type === 'Trivia' ? null : 'none' }}>
          <Trivia
            config={this.props.component.config}
            gameState={this.props.gameState}
            gameData={this.props.gameData}
          />
        </div>
      </div>
    )
  }
}

export default PeerView
